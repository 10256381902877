import axios from 'axios'
import store from "../../store/store.js";
import router from "../../router/index.js";

let loadCounter = (function () {
    let count = 0;
    let loadingInstance;
    const show = () => {
        if (count > 0) {
            // show loading
            // store.state.snackbar = true;
            // store.state.snackmsg = "Data Fetching...";
        } else {
            if (store.state.snackbar) {
                // store.state.snackbar = false;
            }
        }
    }
    return {
        increment() {
            count++;
            show();
        },
        decrement() {
            count--;
            show();
        }
    }
})();

// create an axios instance 
const service = axios.create({
    baseURL: APIURL,
    timeout: 30000,
    crossDomain: true
})

let source = axios.CancelToken.source();

// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

// request interceptor
service.interceptors.request.use(config => {
    // config.headers['Content-Type'] = "application/x-www-form-urlencoded;charset=UTF-8";

    let token = "Bearer " + localStorage.getItem('bst');
    config['headers']['Authorization'] = token;

    loadCounter.increment();

    if (config.method.toLocaleLowerCase() == 'get') {
        // if get method add key to url
    }

    // if (config.method == 'get') {
    // config.data = qs.stringify(config.data)
    // }

    return config
}, error => {
    // do sth with request error
    // console.log(error)
    loadCounter.decrement();
    Promise.reject(error)
})

service.interceptors.response.use(
    response => {
        // console.log("normal Response: ", response)

        // if (response.data.code == 1) {
        store.state.snakmsg = "Request Successfully";
        store.state.snackcolor = 'success';
        // }

        // if (response.data.code == 0) {
        //     store.state.snakmsg = response.data.message;
        //     store.state.snackcolor = 'success';
        // }

        loadCounter.decrement();

        return response
    },
    error => {
        if (error.response) {
            loadCounter.decrement();
            // localStorage.clear();

            switch (error.response.status) {
                case 404:
                    store.state.snackcolor = "red";
                    store.state.snakmsg = "Error 404, Page Not Found";
                    break;
                case 401:
                    store.state.snackcolor = "red";
                    store.state.snakmsg = "Error 401, Auth Expired or Incorrect";

                    // store.state.loginError = true;
                    source.cancel("");
                    if (window.location.href.indexOf("signin") == -1) {
                        // self.$router.push({name: 'SigninPage'});
                        window.location.href = "/#/signin"
                    }
                    break;
                case 500:
                    store.state.snackcolor = "red";
                    if (response.message != null) {
                        store.state.snakmsg = response.message;
                    } else {
                        store.state.snakmsg = "Error 500, Internal Server Error";
                    }
                    break;
                case 400:

                    store.state.snackcolor = "red";
                    store.state.snakmsg = "Error 500, Error";
                    break;
                case 429:

                    store.state.snackcolor = "red";
                    store.state.snakmsg = "Too many request";
                    break;
                case 403:
                    store.state.snackcolor = "red";
                    store.state.snakmsg = "Permission Denied";
                    console.log("Permission Denied!");
                    router.go(-1);
                    break;
            }
        }
    }
)

export default service