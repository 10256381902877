<template>
    <div>
		<v-card>
			<v-container fluid pt-0 mt-0>
				<v-row class="blue-grey darken-4">
                    <Breadcrumbs/>
                </v-row>
				<v-row><v-col cols="12"></v-col></v-row>
                <v-row>
					<v-col cols="3">
                        <v-autocomplete label="Filter Book" 
                            v-model="selectedBrokerId" :items="this.$store.state.brokerMap" item-text="broker" 
                            item-value="broker_id"
                            auto-select-first chips deletable-chips small-chips></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete label="Filter Book" 
                            v-model="selectedBook" :items="listBook" 
                            item-text="book" 
                            item-value="book"
                            auto-select-first chips deletable-chips small-chips></v-autocomplete>
                    </v-col>

                    <v-col cols="3">
                        <v-autocomplete label="Filter Endfix" 
                            v-model="selectedEndfix" :items="symbolEndfix" multiple
                            auto-select-first chips clearable deletable-chips multiple small-chips></v-autocomplete>
                    </v-col>
                    
                    <v-col cols="3">
                        <v-text-field label="Symbol" v-model="inputSymbol" hide-details="auto"></v-text-field>
                    </v-col>
                    
                    <v-col cols="12">
                        <v-textarea filled name="input-7-4" label="Filled textarea" v-model="inputData" rows="10">
                        </v-textarea>
                    </v-col>
                    
                    <v-dialog v-model="modal.visible" width="500">
                        <v-card>
                            <v-card-title class="headline lighten-2">
                                {{ modal.title }}
                            </v-card-title>

                            <v-card-text>
                                <p v-for="(value, name, index) in modal.content" :key="index" class="mt-3 mb-3">
                                    {{ name.toUpperCase() }}: {{ value.toFixed(2) }}
                                </p>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="modal.visible = false">
                                Ok
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-row>
                <v-row>
                    <v-col cols="4"> 
                        <v-btn :loading="btnLoading" class="mr-5"
                            color="#3a88c4" large v-on:click="validateNet()">Validate NET</v-btn>
                        
                        <!-- <v-btn :loading="btnLoadingPnl" class="mr-5" 
                            color="#3a88c4" large v-on:click="validatePnl()">Validate PNL</v-btn> -->
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        
    </div>
</template>
<script>
import * as apiValidateNet from "@components/api/pk/validate-net";
import * as apiValidatePnl from "@components/api/pk/validate-pnl";
import * as apiBook from "@components/api/pk/book";
import Breadcrumbs from "@components/Breadcrumbs";
import { mapState } from "vuex";

export default {
    components: {Breadcrumbs},
    data() {
        return {
            inputData: "",
            outputData: [],
            inputSymbol: "xauusd",
            selectedBook: "",
            btnLoading: false,
            btnLoadingPnl: false,
            selectedBroker: "AUGS",
            selectedBrokerId: 1,
            selectedEndfix: [],
            listBook: [],
            modal: {
                visible: false,
                title: "",
                content: ""
            }
        }
    },
    computed: {
        ...mapState([
            "symbolEndfix"
        ])
    },
    watch: {
        selectedBrokerId(nv, ov) {
            this.loadBookGroup();
        }
    },
    methods: {
        /**
        * Load book group
        * @param {*}  
        */
        loadBookGroup() {
            let self = this;
            let params = {broker_id: this.selectedBrokerId};
            apiBook.fetch(params).then(res => {
                // let data = res.data.data;
                console.log(res);
                self.listBook = res.data;

                if (res.data.length != 0) {
                    self.selectedBook = res.data[0].book;
                }
            })
        },
        validatePnl() {
            let self = this;

            if (self.inputSymbol == "") {
                this.$store.state.snackbar = true;
                this.$store.state.snackcolor = "red";
                this.$store.state.snackmsg = "Please fill in symbol";
                return;
            }

            self.btnLoadingPnl = true;
            
            let params = {};

            params["symbol"] = self.inputSymbol;
            params["broker_id"] = self.selectedBrokerId;
            params["filter_book"] = self.selectedBook; 
            params["trades"] = self.inputData;
            params["filter_endfix"] = self.selectedEndfix;

            console.log(params);
            apiValidatePnl.create(params).then(res => {
                self.btnLoadingPnl = false;
                self.modal.visible = true;
                self.modal.title = "API Result";
                self.modal.content = res.data;
            })
        },
        validateNet() {
            let self = this;

            if (self.inputSymbol == "") {
                this.$store.state.snackbar = true;
                this.$store.state.snackcolor = "red";
                this.$store.state.snackmsg = "Please fill in symbol";
                return;
            }

            self.btnLoading = true;
            
            let params = {};

            params["symbol"] = self.inputSymbol;
            params["broker_id"] = self.selectedBrokerId;
            params["filter_book"] = self.selectedBook; 
            params["trades"] = self.inputData;
            params["filter_endfix"] = self.selectedEndfix;

            console.log(params);
            apiValidateNet.create(params).then(res => {
                self.btnLoading = false;
                self.modal.visible = true;
                self.modal.title = "API Result";
                self.modal.content = res.data;
            })
        }
    },
    mounted() {
        this.loadBookGroup();
    }
}
</script>